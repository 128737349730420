/**
 * @description 公共配置文件
 */
const BASE_URL = 'http://8.140.192.90:8091';
const setting = {
    // 指定后端服务器IP和端口
    //
    LoginUrl: BASE_URL + '/login',
    UpdatePwd_API: BASE_URL + '/UpdatePwd_API',
    GetEquipmentByUserId: BASE_URL + '/getEquipmentByUserId',
    AddEquipment: BASE_URL + '/addEquipment',
    UpdateEquipment: BASE_URL + '/updateEquipment',
    GetAlarmPolicy: BASE_URL + '/getAlarmPolicy',
    UpdateAlarmPolicy: BASE_URL + '/updateAlarmPolicy',
    GetFactorByUserId: BASE_URL + '/getFactorByUserId',
    GetCSDataCPByDate: BASE_URL + '/getCSDataCPByDate',
    GetCSDataCP: BASE_URL + '/getCSDataCP',
    GetMsgDataCP: BASE_URL + '/getMsgDataCP',
    GetMsgDataByDateCP: BASE_URL + '/getMsgDataByDateCP',
    AddFactor: BASE_URL + '/addFactor',
    EditFactor: BASE_URL + '/editFactor',
    DeleteFactor: BASE_URL + '/deleteFactor',
    GetWarningDataCP: BASE_URL + '/getWarningDataCP',
    GetWarningDataByDateCP: BASE_URL + '/getWarningDataByDateCP',
    QueryUserByNameANDPwd: BASE_URL + '/queryUserByNameANDPwd',
    UpdateUI: BASE_URL + '/updateUI',
    UpdateEquipmentUI: BASE_URL + '/updateEquipmentUI',
    GetHistoryByDateCP: BASE_URL + '/getHistoryByDateCP',
    GetHourHistoryByDateCP: BASE_URL + '/getHourHistoryByDateCP',
    GetMinutesHistoryByDateCP: BASE_URL + '/getMinutesHistoryByDateCP',
    GetHistoryCP: BASE_URL + '/getHistoryCP',
    GetAllUserByUserId: BASE_URL + '/getAllUserByUserId',
    AddUserByUserId: BASE_URL + '/addUserByUserId',
}
export default setting