import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/login/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    redirect: '/main',
    children: [
      {
        path: '/main',
        name: 'main',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/MainView.vue')
      },
      {
        path: '/site-siteList',
        name: 'site-siteList',
        meta: {
          isShow: true,
        },
        component: () => import('@/views/siteManagement.vue')
      },
      {
        path: '/data-history',
        name: 'data-history',
        meta: {
          isShow: true,
        },
        component: () => import('@/views/data/dataHistory.vue')
      },
      {
        path: '/data-warn',
        name: 'data-warn',
        meta: {
          isShow: true,
        },
        component: () => import('@/views/data/dataWarning.vue')
      },
      {
        path: '/log-msg',
        name: 'log-msg',
        meta: {
          isShow: true,
        },
        component: () => import('@/views/logMsg.vue')
      },
      {
        path: '/log-wasteWater-parameter',
        name: 'log-wasteWater-parameter',
        meta: {
          isShow: true,
        },
        component: () => import('@/views/logWasteWater.vue')
      },
      {
        path: '/updatePwd',
        name: 'updatePwd',
        meta: {
          isShow: true,
        },
        component: () => import('@/views/updatePwd.vue')
      },
      {
        path: '/factor-management',
        name: 'factor-management',
        meta: {
          isShow: true,
        },
        component: () => import('@/views/factorSetting.vue')
      },
      {
        path: '/dataUI-setting',
        name: 'dataUI-setting',
        meta: {
          isShow: true,
        },
        component: () => import('@/views/data/dataUISetting.vue')
      },
      {
        path: '/user-setting',
        name: 'user-setting',
        meta: {
          isShow: true,
        },
        component: () => import('@/views/UserSetting.vue')
      },
      {
        path: '/videoSurveillance',
        name: 'videoSurveillance',
        meta: {
          isShow: true,
        },
        component: () => import('@/views/videoSurveillance.vue')
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
