<template>
  <div class="login-box">
    <el-form ref="loginFormRef" :model="loginForm" status-icon :rules="rules" label-width="120px" class="loginForm">
      <h1>后台登录</h1>
      <el-form-item label="账号：" prop="userName">
        <el-input v-model="loginForm.userName" type="text" autocomplete="off" />
      </el-form-item>
      <el-form-item label="密码：" prop="passWord">
        <el-input v-model="loginForm.passWord" type="password" autocomplete="off" />
      </el-form-item>
      <el-form-item>
        <el-button class="sub-btn" type="primary" @click="submitForm()">登录</el-button>
        <!-- <el-button @click="resetForm(loginFormRef)">Reset</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import setting from "@/config/setting"
import { InitData } from "@/types/index"
import axios from 'axios';

export default defineComponent({
  setup() {
    const data = reactive(new InitData());
    const rules = {
      // userName: [
      //   { required: false, message: "请输入账号", trigger: "blur" },
      //   { min: 6, max: 14, message: "账号长度在6到14位之间", trigger: "blur" },
      // ],
      passWord: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { min: 6, max: 14, message: "账号长度在6到14位之间", trigger: "blur" },
      ],
    };
    const router = useRouter();
    const submitForm = () => {
      axios({
        method: 'post',
        url: setting.LoginUrl,
        params: {
          userName: data.loginForm.userName,
          userPwd: data.loginForm.passWord,
        }
      }).then((res) => {
        //console.log('res.data', res.data)
        if (res.data.message != "登录成功") {
          ElMessage.success(res.data.message);
        } else {
          if (res.data.user.permissions == "普通用户") {
            localStorage.setItem("LoginUserName", res.data.user.userName);
            localStorage.setItem("LoginUserId", res.data.user.Id);
            localStorage.setItem("LoginUserPwd", res.data.user.userPwd);
            //console.log(JSON.stringify(res.data.user))
            //localStorage.setItem("User", JSON.stringify(res.data.user));
            router.push("/home");
          }else if (res.data.user.permissions == "附属用户") {
            localStorage.setItem("LoginUserName", res.data.user.userName);
            localStorage.setItem("LoginUserId", res.data.user.Id);
            localStorage.setItem("LoginUserPwd", res.data.user.userPwd);
            localStorage.setItem("userEquipment", res.data.user.userEquipment);
            localStorage.setItem("uid", res.data.user.uid);
            //console.log(JSON.stringify(res.data.user))
            //localStorage.setItem("User", JSON.stringify(res.data.user));
            router.push("/affiliatedUser-home");
          }
        }
      }, (rej) => {
        console.log(rej)
        ElMessage.error(rej);
      })
      //console.log(table.permission)

    }
    onMounted(() => {
      history.pushState(null, "", document.URL);
      
      window.addEventListener(
        'popstate',
        function() {
          history.pushState(null, "", document.URL);
        },false);
    });
    return {
      ...toRefs(data),
      rules,
      submitForm,
      
    };
  },
});
</script>

<style scoped>
.login-box {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 200px;
}

.loginForm {
  width: 400px;
  padding: 20px;
  background: rgb(101, 211, 255);
  border-radius: 20px;
  margin: 0 auto;
}

.sub-btn {
  width: 70%;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin: 20px;
}
</style>