import {ref} from 'vue';

export class InitData {
    loginForm = {
        id:0,
        userName:'',
        passWord:'',
        permission:'',
        phone:'',
    }
    loginFormRef =ref()
}